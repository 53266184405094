import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '../../base-page/base-page.component';
import { FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { take } from 'rxjs/operators';
import { LotteryLevelPrize, Prize } from 'src/app/services/client-area/client-area.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TeaserTopMobileService } from 'src/app/services/teaser-top-mobile/teaser-top-mobile.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { bonusQueueTrx, bonusTrx, clientAreaTrx } from 'src/app/router-translation.labels';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { timer } from 'rxjs';
import * as PIXI from 'pixi.js';

declare var confetti: any;

const DESKTOP_SYMBOL_SIZE_HEIGHT = 126;
const DESKTOP_SYMBOL_SIZE_WIDTH = 250;
const MOBILE_SYMBOL_SIZE_HEIGHT = 80;
const MOBILE_SYMBOL_SIZE_WIDTH = 150;
const PATH_TO_SPIN_IMAGES = 'assets/playbison/spin/slots/';
const IMAGES_EXTENSION = '.png';
const DEFAULT_SPIN_IMAGE = 'assets/playbison/spin/slots/bonus_dollars.png';

@Component({
  template: ''
})
export class BonusLotteryWheelRouteComponent extends BasePageComponent {

  constructor(public clientAreaDialog: MatDialog,
  ) { super(); }

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.clientAreaDialog.open(BonusLotteryWheelComponent, {
      ...FULL_DIALOG_CONFIG,
      panelClass: 'lottery-wheel-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.router.navigate(["/"], { relativeTo: this.route, skipLocationChange: false })
    });
  }
}

@Component({
  selector: 'app-bonus-lottery-wheel',
  templateUrl: './bonus-lottery-wheel.component.html',
  styleUrls: ['./bonus-lottery-wheel.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate({{rotationAngle}}deg)' }), { params: { rotationAngle: '180' } }),
      transition('default => rotated', animate('2500ms ease-in-out')),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class BonusLotteryWheelComponent implements OnInit {

  constructor(
    // public dialogRef: MatDialogRef<GameClientAreaComponent>,
    private bonusLotteryService: BonusLotteryService,
    private translationConfig: TranslationConfig,
    public deviceDetect: DetectDeviceService,
    private teserTopService: TeaserTopMobileService,
    public minimizeSiteService: MinimizeSiteService,
    private router: Router,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
  ) { }

  state = 'default';
  isShowWin = false;
  isStartWinAnimation = false;
  prize: Prize;
  isActive = true;
  leftToOpen = 0;
  isFinished = false;
  public bonusQueueUrl = this.translationConfig.getTranslation(clientAreaTrx) + '/' + this.translationConfig.getTranslation(bonusQueueTrx);
  prizeValue: string;
  prizeName: string;
  @ViewChild('confetticanvas') confettiCanvas: ElementRef;
  myConfetti: any;

  @ViewChild('slotMachine') slotMachine: ElementRef;

  private prizes: LotteryLevelPrize[] = [];
  private assets: string[] = [];

  private symbolSize = {
    width: this.deviceDetect.isDesktop() ? DESKTOP_SYMBOL_SIZE_WIDTH : MOBILE_SYMBOL_SIZE_WIDTH,
    height: this.deviceDetect.isDesktop() ? DESKTOP_SYMBOL_SIZE_HEIGHT : MOBILE_SYMBOL_SIZE_HEIGHT,
  };

  private pixiApp: PIXI.Application = new PIXI.Application({
    backgroundAlpha: 0,
    width: this.symbolSize.width,
    height: this.symbolSize.height,
  });

  private isRunning = false;
  private reel;

  private tweening = [];


  ngOnInit(): void {
    this.getWheelInfo();
  }

  ngAfterViewInit() {
    this.bonusLotteryService.getLotteryLevelPrizes().subscribe(prizes => {
      this.prizes = prizes.reverse();
      this.assets = [DEFAULT_SPIN_IMAGE].concat(prizes.map(prize => PATH_TO_SPIN_IMAGES + prize.assetName + IMAGES_EXTENSION));
      this.initPixi(this.assets);
    });
  }

  getWheelInfo() {
    this.bonusLotteryService.getWheelInfo().pipe(take(1)).subscribe(info => {
      this.leftToOpen = info.leftToOpen;
      if (this.leftToOpen > 0) {
        this.isActive = true;
      }
      else {
        // this.teserTopService.disableActivePromoTeaser();
        this.isFinished = true;
        this.isActive = false;
      }
    });

  }

  spinWheel() {
    this.isStartWinAnimation = false;
    this.state = 'default';
    this._ngZone.runOutsideAngular(() => {
      this.myConfetti = confetti.create(this.confettiCanvas.nativeElement, {
        resize: true,
        useWorker: true
      });
    });

    if (this.isRunning || this.reel == null || this.assets.length === 0) {
      return;
    }

    if (this.isActive) {
      this.isActive = false;
      this.isRunning = true;
      this.state = 'rotated';

      this.bonusLotteryService.startLottery().pipe(take(1)).subscribe({
        next: prize => {
          this.bonusLotteryService.resetStore();
          this.getWheelInfo();
          this.prize = prize;
          const [first, ...rest] = prize.displayName.split(' ');
          this.prizeValue = first;
          this.prizeName = rest.join(' ');

          const extra = this.assets.length - this.prizes.findIndex(p => p.systemLabel === prize.systemLabel) || 0;
          const target = this.reel.position + (this.assets.length - (this.reel.position % this.assets.length)) + this.assets.length * 10 + extra;
          const time = 2500 + 600;

          this.tweenTo(this.reel, 'position', target, time, this.backout(0.5), null, () => { this.isRunning = false; });

          setTimeout(() => {
            this.animDone();
          }, time);
        }, error: err => {
          this.getWheelInfo();
        }
      });
    }
  }

  animDone() {
    if (!!this.myConfetti) {
      timer(100).subscribe(() => {
        this.myConfetti({
          particleCount: 100,
          spread: 160,
          origin: { y: 0.7 }

        });
        this.myConfetti({
          pread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
          origin: { y: 0.7 }

        });
      });
    }

    this.state = 'default';
    this.isStartWinAnimation = true;
  }

  overlayClicked() {
    if (this.isShowWin) {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.minimizeSiteService.restorePage();
    this.router.navigate(["", clientAreaTrx, bonusQueueTrx], { skipLocationChange: false })

  }

  private initPixi(assets: string[]) {
    this.slotMachine.nativeElement.appendChild(this.pixiApp.view);
    PIXI.Assets.load(assets).then(_ => this.onAssetsLoaded(assets));
  }

  private onAssetsLoaded(assets: string[]) {
    const slotTextures = assets.map((asset) => {
      return PIXI.Texture.from(asset);
    });

    const reelContainer = new PIXI.Container();

    const reel = {
      container: reelContainer,
      symbols: [],
      position: 0,
      previousPosition: 0,
      blur: new PIXI.BlurFilter(),
    };
    reel.blur.blurX = 0;
    reel.blur.blurY = 0;
    reelContainer.filters = [reel.blur];

    const emptySprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
    reel.symbols.push(emptySprite);
    reelContainer.addChild(emptySprite);

    // Build the symbols
    for (let j = 0; j < assets.length; j++) {
      const symbol = new PIXI.Sprite(slotTextures[j]);
      // Scale the symbol to fit symbol area.
      symbol.scale.x = symbol.scale.y = Math.min(this.symbolSize.width / symbol.width, this.symbolSize.height / symbol.height);
      symbol.y = j * this.symbolSize.height + (this.symbolSize.height - symbol.height) / 2;
      symbol.x = Math.round((this.symbolSize.width - symbol.width) / 2);

      reel.symbols.push(symbol);
      reelContainer.addChild(symbol);
    }

    this.pixiApp.stage.addChild(reelContainer);
    this.reel = reel;

    // Listen for animate update.
    this.pixiApp.ticker.add((delta) => {
      // Update the slots.
      const r = this.reel;
      // Update blur filter y amount based on speed.
      // This would be better if calculated with time in mind also. Now blur depends on frame rate.
      r.blur.blurY = (r.position - r.previousPosition) * 8;
      r.previousPosition = r.position;

      // Update symbol positions on reel.
      for (let j = 0; j < r.symbols.length; j++) {
        const s = r.symbols[j];
        const prevy = s.y;
        s.y = ((r.position + j) % r.symbols.length) * this.symbolSize.height + (this.symbolSize.height - s.height) / 2 - this.symbolSize.height;
        if (s.y < 0 && prevy > this.symbolSize) {
          // Detect going over and swap a texture.
          // This should in proper product be determined from some logical reel.
          s.texture = slotTextures[j];
          s.scale.x = s.scale.y = Math.min(this.symbolSize.width / s.texture.width, this.symbolSize.height / s.texture.height);
          s.x = Math.round((this.symbolSize.width - s.width) / 2);
        }
      }
    });

    this.pixiApp.ticker.add((delta) => {
      const now = Date.now();
      const remove = [];
      for (const t of this.tweening) {
        const phase = Math.min(1, (now - t.start) / t.time);
        t.object[t.property] = this.lerp(t.propertyBeginValue, t.target, t.easing(phase));
        if (t.change) {
          t.change(t);
        }
        if (phase === 1) {
          t.object[t.property] = t.target;
          if (t.complete) {
            t.complete(t);
          }
          remove.push(t);
        }
      }
      for (const item of remove) {
        this.tweening.splice(this.tweening.indexOf(item), 1);
      }
    });
  }

  private lerp(a1, a2, t) {
    return a1 * (1 - t) + a2 * t;
  }

  private tweenTo(object, property, target, time, easing, onchange, oncomplete) {
    const tween = {
      object,
      property,
      propertyBeginValue: object[property],
      target,
      easing,
      time,
      change: onchange,
      complete: oncomplete,
      start: Date.now(),
    };

    this.tweening.push(tween);
    return tween;
  }

  private backout(amount) {
    return (t) => (--t * t * ((amount + 1) * t + amount) + 1);
  }
}
