import { Component, ElementRef, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrizeBox, PrizeBoxesLevelLottery } from 'src/app/services/client-area/client-area.models';
import { PrizeBoxService } from '../../../services/client-area/prize-box.service';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PrizeInfoDialogComponent } from './prize-info-dialog/prize-info-dialog.component';
import { SMALL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { Router } from '@angular/router';
import { clientAreaMainTrx, clientAreaTrx, infoTrx, treasureBoxesTrx } from 'src/app/router-translation.labels';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import * as PixiSpine from 'pixi-spine';
import * as PIXI from 'pixi.js';

export enum LotteryState {
  EMPTY,
  START,
  NEXT_LEVEL,
  FINISHED
}

const ITEMS_PER_ROW = 3;
const DESKTOP_SYMBOL_SIZE_HEIGHT = 180;
const DESKTOP_SYMBOL_SIZE_WIDTH = 220;
const MOBILE_SYMBOL_SIZE_HEIGHT = 90;
const MOBILE_SYMBOL_SIZE_WIDTH = 110;
const ANIMATION_SIZE_WIDTH = 940;
const PATH_TO_SPIN_IMAGES = 'assets/playbison/lottery-card/lottery_card.json';

@Component({
  selector: 'app-prize-boxes',
  templateUrl: './prize-boxes.component.html',
  styleUrls: ['./prize-boxes.component.scss']
})
export class PrizeBoxesComponent extends BasePageComponentWithDialogs {
  emptyMessage = $localize`:@@empty-prizebox-click:Locked till you reach next gamification level.`;
  readonly treasureBoxInfoUrl: string = `/${this.translationConfig.getTranslation(infoTrx)}/${this.translationConfig.getTranslation(treasureBoxesTrx)}`;

  isShowClickTeaser = true;
  prizeBoxLottery: PrizeBoxesLevelLottery;
  leftToOpen: number;
  showBoxes = false;
  emptyBoxesArray: number[] = Array(9);
  askForNextLevel = false;
  lotteryState: LotteryState = LotteryState.EMPTY;
  lockPrizeBoxClick: Set<number> = new Set<number>();
  lotteryStateEnum = LotteryState;
  isClaimed: boolean;
  isSelectionPrizeVolatilityType: boolean;
  app: PIXI.Application;
  canvasHeight: number;

  mobileAnimationFontStyle = new PIXI.TextStyle({
    align: 'center',
    dropShadow: true,
    dropShadowAlpha: 0.8,
    dropShadowAngle: 1.5,
    dropShadowDistance: 1.5,
    dropShadowBlur: 2,
    fill: '#ffffff',
    fontFamily: 'Helvetica',
    fontSize: 16,
    lineHeight: 17,
    wordWrap: true,
    breakWords: true,
    wordWrapWidth: 60
  });

  desktopAnimationFontStyle = new PIXI.TextStyle({
    align: 'center',
    dropShadow: true,
    dropShadowAlpha: 0.8,
    dropShadowAngle: 1.5,
    dropShadowDistance: 1.5,
    dropShadowBlur: 2,
    fill: '#ffffff',
    fontFamily: 'Helvetica',
    fontSize: 24,
    wordWrap: true,
    breakWords: true,
    wordWrapWidth: 110
  });

  @ViewChild('animatedBox') animatedBox: ElementRef;

  private symbolSize = {
    width: this.detectDesktopService.isDesktop()
      ? DESKTOP_SYMBOL_SIZE_WIDTH
      : MOBILE_SYMBOL_SIZE_WIDTH,
    height: this.detectDesktopService.isDesktop()
      ? DESKTOP_SYMBOL_SIZE_HEIGHT
      : MOBILE_SYMBOL_SIZE_HEIGHT,
    scale: this.detectDesktopService.isDesktop()
      ? DESKTOP_SYMBOL_SIZE_WIDTH / ANIMATION_SIZE_WIDTH
      : MOBILE_SYMBOL_SIZE_WIDTH / ANIMATION_SIZE_WIDTH,
    offset: this.detectDesktopService.isDesktop()
      ? DESKTOP_SYMBOL_SIZE_WIDTH * DESKTOP_SYMBOL_SIZE_HEIGHT / (ANIMATION_SIZE_WIDTH * 2)
      : MOBILE_SYMBOL_SIZE_WIDTH * MOBILE_SYMBOL_SIZE_HEIGHT / (ANIMATION_SIZE_WIDTH * 2)
  };

  constructor(
    public prizeBoxService: PrizeBoxService,
    private snackBar: MatSnackBar,
    public detectDesktopService: DetectDeviceService,
    public infoDialog: MatDialog,
    public errorDialog: MatDialog,
    public translationConfig: TranslationConfig,
    private router: Router,
    private minimizeFooterService: MinimizeSiteService,
    protected licenceService: LicenceService
  ) {
    super(errorDialog, detectDesktopService);
  }

  ngOnInit(): void {
    this.canvasHeight = this.symbolSize.height * ITEMS_PER_ROW + this.symbolSize.offset * (ITEMS_PER_ROW - 1) * 2;
    this.getPrizeBoxes();
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  ngAfterViewInit() {
    this.app = new PIXI.Application({
      preserveDrawingBuffer: true,
      backgroundAlpha: 0,
      width: this.symbolSize.width * ITEMS_PER_ROW + this.symbolSize.offset * 2,
      height: this.symbolSize.height * ITEMS_PER_ROW + this.symbolSize.offset * (ITEMS_PER_ROW - 1) * 2,
    });
    const checkInterval = setInterval(() => {
      if (this.animatedBox && this.animatedBox.nativeElement) {
        clearInterval(checkInterval);
        PIXI.Assets.load(PATH_TO_SPIN_IMAGES).then(this.onAssetsLoaded);
      }
    }, 100);
  }

  onAssetsLoaded = (prizeboxAsset) => {

    if (this.prizeBoxLottery) {
      this.prizeBoxLottery.prizeBoxes = this.prizeBoxLottery.prizeBoxes
        .filter((prizeBox) => prizeBox.index < 9)
        .sort((a, b) => b.index - a.index);
      let xOffset = 0;
      let yOffset = 0;

      this.prizeBoxLottery.prizeBoxes.forEach((prizeBox, index) => {
        const rowIndex = Math.floor(index / ITEMS_PER_ROW);
        const columnIndex = index % ITEMS_PER_ROW;
        xOffset = columnIndex * (this.symbolSize.width + this.symbolSize.offset);
        yOffset = rowIndex * (this.symbolSize.height + this.symbolSize.offset * 2);

        const prizeBoxSpine = new PixiSpine.Spine(prizeboxAsset.spineData);
        const singleAnimations = ['opening', 'win'];
        const loopAnimations = ['idle'];
        const allAnimations = [].concat(singleAnimations, loopAnimations);

        prizeBoxSpine.state.data.skeletonData.bones;
        prizeBoxSpine.scale.x = this.symbolSize.scale;
        prizeBoxSpine.scale.y = this.symbolSize.scale;
        prizeBoxSpine.x = this.symbolSize.width / 2 + xOffset;
        prizeBoxSpine.y = this.symbolSize.height / 2 + yOffset;

        if (prizeBox.isOpen) {
          prizeBoxSpine.state.setAnimation(0, 'win', false);

          const style = this.detectDesktopService.isDesktop() ? this.desktopAnimationFontStyle : this.mobileAnimationFontStyle;
          const text = new PIXI.Text(prizeBox.prize?.displayName, style);
          text.anchor.set(0.5);
          text.x = prizeBoxSpine.x;
          text.y = prizeBoxSpine.y;
          prizeBoxSpine.on('pointerdown', async () => {
            this.openInfoDialog();
          });
          prizeBoxSpine.interactive = true;
          text.on('pointerdown', async () => {
            this.openInfoDialog();
          });
          text.interactive = true;

          if (this.app && this.app.stage) {
            this.app.stage.addChild(prizeBoxSpine);
            this.app.stage.addChild(text);
          }
        } else {
          prizeBoxSpine.state.setAnimation(0, 'idle', true);

          if (this.app && this.app.stage) {
            this.app.stage.addChild(prizeBoxSpine);
          }
          prizeBoxSpine.interactive = true;
          prizeBoxSpine.on('pointerdown', async () => {

            if (prizeBox.isOpen) {
              this.openInfoDialog();
            } else {
              await this.openPrizeBox(prizeBox);

              if (prizeBox.isOpen) {
                const style = this.detectDesktopService.isDesktop() ? this.desktopAnimationFontStyle : this.mobileAnimationFontStyle;
                const text = new PIXI.Text(prizeBox.prize?.displayName, style);

                text.anchor.set(0.5);
                text.x = prizeBoxSpine.x;
                text.y = prizeBoxSpine.y;
                if (!text.text) {
                  text.text = 'EMPTY BOX';
                }
                text.on('pointerdown', async () => {
                  this.openInfoDialog();
                });
                text.interactive = true;

                let openingAnimation = prizeBoxSpine.state.setAnimation(0, 'opening', false);

                prizeBoxSpine.state.addAnimation(0, 'win', false, 0);

                const duration = openingAnimation.animationEnd;
                setTimeout(() => {
                  if (this.app && this.app.stage) {
                    this.app.stage.addChild(text);
                  }
                }, duration * 1000);
              }
            }
          });
        }
        xOffset = xOffset + prizeBoxSpine.x + this.symbolSize.width + this.symbolSize.offset;
        yOffset = yOffset + prizeBoxSpine.y + this.symbolSize.height + this.symbolSize.offset * 2;
        if (this.app && this.app.stage) {
          this.app.stage.interactive = true;
        }

        if (this.animatedBox && this.animatedBox.nativeElement && this.app && this.app.view) {
          this.animatedBox.nativeElement.appendChild(this.app.view);
        }
      });
    }
  };

  ngOnDestroy() {
    this.app?.destroy();
    this.app = null;
  }

  getPrizeBoxes() {
    this.askForNextLevel = false;
    this.prizeBoxService.updateCurrentLevelLottery()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((resp) => {
        this.prizeBoxLottery = resp;
        if (resp) {
          this.lockPrizeBoxClick.clear();
          this.isSelectionPrizeVolatilityType = resp.isSelectionPrizeVolatilityType;
          this.leftToOpen = resp.leftToOpen;
          this.showBoxes = true;
          this.isClaimed = true;
          this.lotteryState = LotteryState.START;
        }
      });
  }

  checkIfNextLevel() {
    this.prizeBoxService.updateCurrentLevelLottery()
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((resp) => {
        if (resp) {
          this.askForNextLevel = true;
          this.lotteryState = LotteryState.NEXT_LEVEL;
        } else {
          this.lotteryState = LotteryState.FINISHED;
        }
      });
  }

  openPrizeBox(prizeBox: PrizeBox): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (prizeBox.isOpen) {
        resolve();
        return;
      }
      if (this.lockPrizeBoxClick.has(prizeBox.index)) {
        resolve();
        return;
      } else {
        this.lockPrizeBoxClick.add(prizeBox.index);
      }
      if (this.leftToOpen > 0) {
        this.leftToOpen -= 1;
        this.prizeBoxService.openPrizeBox(this.prizeBoxLottery.uid, prizeBox)
          .pipe(take(1))
          .subscribe(
            (prize) => {
              prizeBox.prize = prize;
              prizeBox.isOpen = true;
              this.prizeBoxService.initialLottery();
              // if (this.leftToOpen === 0) this.checkIfNextLevel();
              this.lotteryState = LotteryState.FINISHED;
              resolve();
            },
            (error) => {
              this.openErrorDialog();
              reject(error);
            }
          );
        this.isShowClickTeaser = false;
      } else {
        resolve();
      }
    });
  }

  openInfoDialog(): void {
    const dialogRef = this.infoDialog.open(PrizeInfoDialogComponent, {
      ...SMALL_DIALOG_CONFIG
    });
  }

  expand() {
    this.isClaimed = true;
  }

  openInfo() {
    this.snackBar.open(this.emptyMessage, 'X', {
      panelClass: 'my-snackbar',
      duration: 2500
    });
  }

  close() {
    this.router.navigate(['', clientAreaTrx, clientAreaMainTrx]).then(() => {
      this.minimizeFooterService.restorePage();
    });
  }

  selectPrizeBoxType(index: number) {
    this.prizeBoxService
      .postSelectPrizeBoxVolatilityType(this.prizeBoxLottery.uid, this.prizeBoxLottery.selectionPrizeBoxVolatilityNames[index])
      .subscribe({
        next: () => {
          this.getPrizeBoxes();
        },
        error: () => {
          this.openErrorDialog();
        }
      });
  }


}
